import React, { FC, useEffect, useState, useRef, Fragment } from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

interface IJupyterIFrameProps {
  url: string;
}
const JupyterIFrame: FC<IJupyterIFrameProps> = ({
  url
}) => {
  const classes = useStyles({});
  return (
    <Fragment>
      <iframe
        title={'Lab'}
        className={classes.jupyterIFrame}
        src={url}
      />
    </Fragment>
  );
};

export default JupyterIFrame;