import React from 'react';
import { Grid, Container, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { Typography } from 'components/Wrappers/Wrappers';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';

const Pricing = () => {
  const classes = useStyles();
  const history = useHistory();
  const cbInstance = (window as any).Chargebee.getInstance()
  const handleCancel = () => {
    history.push('/app/lab');
  };
  const handleBeginnerBuy = () => {
    let cart = cbInstance.getCart();
    let product = cbInstance.initializeProduct('beginner');
    cart.replaceProduct(product);
    cart.proceedToCheckout();
  };

  return (
    <Container>
      <div className={classes.introductionContainer}>
        <Typography variant='h1' className={classes.title}>Start your data science Lab</Typography>
      </div>
      <Grid container spacing={3} className={classes.planCards}>
        <Grid item xs>
          <Card className={classes.beginnerCard} elevation={3}>
            <CardContent>
              <Typography variant='h3' className={classes.planTitle}>Beginner</Typography>
              <Typography variant='h1' className={classes.planPrice}>$10</Typography>
              <Typography variant='body1' className={classes.planDescription}>Per Month</Typography>
              <div className={classes.descriptionContainer}>
                <Typography variant='body1' className={classes.text}>CPU: <strong>1 Shared CPU</strong></Typography>
                <Typography variant='body1' className={classes.text}>RAM: <strong>614 MB</strong></Typography>
                <Typography variant='body1' className={classes.text}>Disk Space: <strong>20 GB</strong></Typography>
              </div>
            </CardContent>
            <CardActions className={classes.flexGrow}>
              <Button
                variant='outlined'
                onClick={handleBeginnerBuy}
                className={classes.buyButton}
              >
                Buy Now
              </Button>
            </CardActions>
          </Card>
          <div className={classes.bottomContainer}>
            <Button color='primary' onClick={handleCancel}>Maybe later</Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pricing;