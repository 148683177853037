import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'relative',
    flex: 1,
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
  }
}));