import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom';

// styles
import useStyles from './styles';

// components
import Header from 'components/Header/Header';
// import Sidebar from '../Sidebar';

// pages
import Lab from 'pages/lab';
import Pricing from 'pages/pricing';
import DataMart from 'pages/datamart/DataMart';

// context
// import { useLayoutState } from 'context/LayoutContext';

const Layout = (props: any) => {
  const classes = useStyles();

  // global
  // const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Switch>
        <Route exact path='/app/home' render={() => <Redirect to='/app/lab' />} />
        <Route exact path='/app/lab' component={Lab} />
        <Route exact path='/app/upgrade' component={Pricing} />
        <Route exact path='/app/datamart' component={DataMart} />
      </Switch>
    </div>
  );
};

export default withRouter(Layout);