import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

interface IUserState {
  user?: any;
  isAuthenticated?: boolean;
}

interface IUserContext {
  isAuthenticated?: boolean;
  user?: any;
  project?: any;
}

interface IUserProvider {
  children: any;
}

const UserStateContext = React.createContext<IUserContext>({});
const UserDispatchContext = React.createContext({});

const userReducer = (state: IUserState, action: IStateAction) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true, ...action.payload };
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false, ...action.payload };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false, ...action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UserProvider = ({ children }: IUserProvider) => {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
};

const useUserDispatch = () => {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
};

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  createAccount,
  loginUser,
  loginWithGoogle,
  signOut
};

// ###########################################################
const loginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  
  provider.addScope('profile');
  provider.addScope('email');
  firebase.auth().signInWithRedirect(provider);
};

const createAccount = (
  dispatch: any,
  name: string,
  login: string,
  password: string,
  history: any,
  setIsLoading: (val: boolean) => void,
  setError: (val: boolean | null) => void,
) => {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        login,
        password,
      )
      .then(() => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          currentUser
            .updateProfile({
              displayName: name,
            });
        }
        setError(null);
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS' });
        history.push('/app/home');
      }).catch((error) => {
        dispatch({ type: 'LOGIN_FAILURE' });
        setError(true);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
};

const loginUser = (
  dispatch: any,
  login: string,
  password: string,
  history: any,
  setIsLoading: (val: boolean) => void,
  setError: (val: boolean | null) => void,
) => {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    firebase
      .auth()
      .signInWithEmailAndPassword(
        login,
        password,
      )
      .then(() => {
        setError(null);
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS' });
        history.push('/app/home');
      }).catch((error) => {
        dispatch({ type: 'LOGIN_FAILURE' });
        setError(true);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
};

const signOut = (dispatch: any, history: any) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: 'SIGN_OUT_SUCCESS' });
      history.push('/login');
    }).catch((error) => {
      // An error happened.
      dispatch({ type: 'SIGN_OUT_FAILURE' });
    });
};