import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme: any) =>
  createStyles({
    logotype: {
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    appBar: {
      width: '100vw',
      backgroundColor: 'transparent',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
      position: 'relative',
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    grow: {
      flexGrow: 1,
    },
    messageContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerMenu: {
      marginTop: theme.spacing(5),
    },
    headerMenuList: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerMenuItem: {
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
    },
    headerIcon: {
      fontSize: 28,
      color: theme.palette.primary.main,
    },
    headerIconCollapse: {
      color: 'rgba(255, 255, 255, 0.35)',
    },
    profileMenu: {
      minWidth: 265,
    },
    profileMenuUser: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    profileMenuItem: {
      color: theme.palette.text.hint,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
    },
    profileMenuLink: {
      fontSize: 16,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    messageNotification: {
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      '&:hover, &:focus': {
        backgroundColor: theme.palette.background.light,
      },
    },
    messageNotificationSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },
    messageNotificationBodySide: {
      alignItems: 'flex-start',
      marginRight: 0,
    },
    sendMessageButton: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: 'none',
    },
    sendButtonIcon: {
      marginLeft: theme.spacing(2),
    },
  }));