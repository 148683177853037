import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography color='primary' className={classes.copyright}>
        {'©2020 Theta Lab, All rights reserved.'}
      </Typography>
    </div>
  );
};

export default Footer;