import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import { billingAPIURL } from 'config';

export const useBilling = () => {
  const getChargeBeeSession = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const result = await axios.post(`${billingAPIURL}/session`);

      return result.status === 200;
    }
    return false;
  };

  return {
    getChargeBeeSession
  };
};