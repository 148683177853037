import React, { useEffect, useState, Fragment } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom';
import Error from './pages/error';
import Login from './pages/login';
import { useUserState, useUserDispatch } from './context/UserContext';
import Layout from './components/Layout/Layout';
import Landing from 'pages/landing/Landing';
import { useQuery } from 'hooks/useQuery';
import PageLoading from 'components/PageLoading';

const RedirectURIChecker = () => {
  const query = useQuery();
  const history = useHistory();
  const userDispatch = useUserDispatch() as any;

  const redirectURI = query.get('redirectURI');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userState = useUserState();

  useEffect(() => {
    if (redirectURI) {
      history.replace(redirectURI);
    }
  }, [userState.isAuthenticated]);

  useEffect(() => {
    setIsLoading(true);
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          userDispatch({ type: 'LOGIN_SUCCESS', payload: { user } });
          history.push('/app/lab');
        }
        setIsLoading(false);
      });

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        console.log('getRedirectResult', result);
        const user = result.user;
        if (user) {
          userDispatch({ type: 'LOGIN_SUCCESS', payload: { user } });
        }
      })
      .catch((error) => {
        console.error('getRedirectResult', error);
        userDispatch({ type: 'LOGIN_FAILURE', payload: { error: error.message } });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {isLoading && <PageLoading />}
    </Fragment>
  );
};

const App: React.FC = () => {

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Landing} />
        <PrivateRoute path='/app' component={Layout} />
        <Route path='/login' component={Login} />
        <Route path='*' component={Error} />
      </Switch>
      <RedirectURIChecker />
    </Router>
  );
}

export default App;

const PrivateRoute = ({ component, ...rest }: any) => {
  const { isAuthenticated } = useUserState();
  const { pathname: redirectURI } = rest.location;
  const pathname = redirectURI ? `/login?redirectURI=${redirectURI}` : `/login`;

  return isAuthenticated ? <Route {...rest} component={component} /> : <Redirect to={pathname} />;
};
