import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import { computeAPIURL, datalabProxy } from 'config';
import { useAccount } from './useAccount';

export const useCompute = () => {
  const account = useAccount();
  const requestCompute = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return await axios.get(`${computeAPIURL}/request`);
    }
    return null;
  };

  const checkCompute = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const result = await axios.get(`${computeAPIURL}/check`);

      return result.status === 200;
    }
    return false;
  };

  const getComputeURI = async (): Promise<string | null> => {
    const user = firebase.auth().currentUser;
    if (user) {
      const uid = user.uid;
      const userAccount = await account.getAccount();
      if(userAccount) {
        const token = userAccount.token;

        return `${datalabProxy}/auth/${uid}?token=${token}`;
      }
    }
    return null;
  };

  return {
    requestCompute,
    checkCompute,
    getComputeURI,
  };
};