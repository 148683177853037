import React from 'react';

interface ILayoutState {
  isSidebarOpened?: boolean;
}

interface ILayoutContext {
  isSidebarOpened?: boolean;
}

interface ILayoutProvider {
  children: any;
}

const LayoutStateContext = React.createContext<ILayoutContext>({});
const LayoutDispatchContext = React.createContext({});

const layoutReducer = (state: ILayoutState, action: IStateAction) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const LayoutProvider = ({ children }: ILayoutProvider) => {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

const useLayoutState = () => {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

const useLayoutDispatch = () => {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

// ###########################################################
const toggleSidebar = (dispatch: any) => {
  dispatch({
    type: 'TOGGLE_SIDEBAR',
  });
};