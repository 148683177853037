import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => createStyles({
  root: {
    width: '100vw',
    height: `100vh`,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 9999,
  },
  progressContainer: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));