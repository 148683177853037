import React, { useState, FC, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Person as UserIcon,
  ExitToAppOutlined as ExitToAppIcon,
  AccountBalance as AccountBalanceIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import { Typography } from '../Wrappers/Wrappers';

// context
import { useUserDispatch, signOut, useUserState } from '../../context/UserContext';
import { useBilling } from 'hooks/useBilling';
import { useAccount } from 'hooks/useAccount';

const Chargebee = (window as any).Chargebee;
const Header: FC<any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // global
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const billing = useBilling();
  const user = userState.user;

  // local
  const [profileMenu, setProfileMenu] = useState<any>(null);

  const cbInstance = Chargebee.getInstance();
  const cbPortal = cbInstance.createChargebeePortal();
  const handleShowPortal = () => {
    const callbacks = {};

    cbPortal.open(callbacks,
      {
        sectionType: Chargebee.getPortalSections().ACCOUNT_DETAILS,
      }
    );
  };

  useEffect(() => {
    cbInstance.setPortalSession(() => {
      return new Promise((reolve, reject) => {
        billing
          .getChargeBeeSession()
          .then(result => reolve(result))
          .catch(err => {
            alert('You don\'t have account associated.');
            reject(err);
          });
      });
    });
  }, []);

  return (
    <AppBar position='fixed' className={classes.appBar} >
      <Toolbar variant='dense' className={classes.toolbar}>
        <Typography variant='h6' weight='medium' className={classes.logotype}>
          Theta Lab
        </Typography>
        <Button onClick={() => { history.push('/app/lab') }}>
          Lab
        </Button>
        <Button onClick={()=> {history.push('/app/datamart')}}>
          Data Mart
        </Button>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup='true'
          color='inherit'
          className={classes.headerMenuButton}
          aria-controls='profile-menu'
          onClick={(e: any) => setProfileMenu(e.currentTarget)}
        >
          <UserIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id='profile-menu'
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant='h4' weight='medium'>
              {user && user.displayName}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={handleShowPortal}
          >
            <AccountBalanceIcon className={classes.profileMenuIcon} /> Billing
            </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => signOut(userDispatch, props.history)}
          >
            <ExitToAppIcon className={classes.profileMenuIcon} /> Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;