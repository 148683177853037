import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => createStyles({
  jupyterIFrame: {
    width: '100%',
    height: `100%`,
    minWidth: '800px',
    minHeight: '600px',
    border: 0,
    position: 'absolute',
  },
}));