import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    introductionContainer: {

    },
    title: {
      textAlign: 'center',
      paddingTop: 30,
      paddingBottom: 20,
      fontSize: '60px !important',
    },
    planCards: {
      marginTop: 20,
      width: 700,
      height: 500,
      margin: 'auto',
    },
    beginnerCard: {
      color: 'white',
      width: '100%',
      height: '100%',
      background: '#40bfc1',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      maxWidth: 350,
    },
    flexGrow: {
      flexGrow: 1,
    },
    planTitle: {
      textAlign: 'center',
    },
    planPrice: {
      paddingTop: 50,
      fontSize: '70px !important',
      fontWeight: 700,
      textAlign: 'center',
    },
    planDescription: {
      textAlign: 'center',
    },
    text: {
      textAlign: 'center',
    },
    descriptionContainer: {
      paddingTop: 50,
    },
    buyButton: {
      margin: 'auto',
      borderColor: 'white',
      fontSize: 30,
      color: 'white'
    },
    customizeCard: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    unavailableText: {
      marginTop: '40%',
      fontSize: 40,
      textAlign: 'center',
    },
    bottomContainer: {
      marginTop: 10,
      textAlign: 'center',
    }
  }),
);