import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography } from '@material-ui/core';
import JupyterIFrame from 'components/Jupyter/JupyterIFrame';
import { useCompute } from 'hooks/useCompute';
import useStyles from './styles';
import { useAccount } from 'hooks/useAccount';
import { useHistory } from 'react-router-dom';

const Lab = () => {
  const timerRef = useRef<any>();
  const [computeURI, setComputeURI] = useState<string>();
  const [userAccount, setUserAccount] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestingCompute, setIsRequestingCompute] = useState<boolean>(false);
  const classes = useStyles();
  const compute = useCompute();
  const history = useHistory();
  const account = useAccount();
  const handleStartLab = () => {
    if (userAccount.isPaid) {
      if (!isRequestingCompute) {
        compute.requestCompute();
        check();
        setIsRequestingCompute(true);
      }
    } else {
      history.push('/app/upgrade');
    }
  };

  const check = () => {
    timerRef.current = setInterval(() => {
      compute
        .checkCompute()
        .then((result) => {
          if (result) {
            compute.getComputeURI().then(uri => uri ? setComputeURI(uri) : null);
            clearInterval(timerRef.current);
            timerRef.current = null;
            setIsRequestingCompute(false);
          }
        });
    }, 5000);
  };

  useEffect(() => {
    setIsLoading(true);
    compute
      .checkCompute()
      .then((result) => {
        if (result) {
          compute.getComputeURI().then(uri => uri ? setComputeURI(uri) : null);
        }
        setIsLoading(false);
      })
      .catch(() => {
        account.getAccount().then((result) => {
          if (result) {
            setUserAccount(result);
          }
          setIsLoading(false);
        });
      });

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const classNames = [classes.container];

  if (!computeURI) {
    classNames.push(classes.flexContainer);
  }

  return (
    <div className={classNames.join(' ')}>
      {computeURI && <JupyterIFrame url={computeURI} />}
      {!computeURI && !isRequestingCompute && <Button disabled={isLoading} variant='outlined' color='primary' onClick={handleStartLab}>Start Lab</Button>}
      {!computeURI && isRequestingCompute && (
        <Typography variant='h1'>
          Please wait
        </Typography>
      )}
    </div>
  );
};

export default Lab;