import firebase from 'firebase/app';
import 'firebase/firestore';
import * as _ from 'lodash';
import axios from 'axios';
import { datamartAPIURL } from 'config';

const convertDoc = (doc: any) => {
  return {
    id: doc.id,
    ...doc.data(),
  }
}
export const useDataMart = () => {
  const getDatasets = async () => {
    try {
      const result = await firebase.firestore().collection('Datasets').get();
      if (!result.empty) {
        return _.map(result.docs, doc => convertDoc(doc));
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };
  const download = async (id: string) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const result = await axios.get(`${datamartAPIURL}/download/${id}`);

      return result.status === 200;
    }
    return false;
  };

  return {
    getDatasets,
    download,
  };
};