import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme: any) =>
  createStyles({
    footer: {
      textAlign: 'center',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
      },
    },
    copyright: {
      marginTop: theme.spacing(4),
      whiteSpace: 'nowrap',
      width: '100%',
      textAlign: 'center',
    },
  }));