import React, { useEffect, useState, Fragment } from 'react';
import { useDataMart } from 'hooks/useDataMart';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Container, Typography } from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';

const DataMart = () => {
  const dataMart = useDataMart();
  const [datasets, setDatasets] = useState<any[]>([]);
  const handleDownload = async (id: string) => {
    try {
      await dataMart.download(id);
      alert('Success to download.')
    } catch (err) {
      alert('Fail to download.')
    }
  };

  useEffect(() => {
    dataMart
      .getDatasets()
      .then(result => {
        if (result) {
          setDatasets(result);
        }
      })
  }, []);

  return (
    <Container maxWidth='md'>
      <Typography variant='h3'>
        Datasets
      </Typography>
      <List>
        {datasets.map(dataset => (
          <Fragment key={dataset.id}>
            <ListItem >
              <ListItemText>
                {dataset.name}
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton color='primary' onClick={() => handleDownload(dataset.id)}>
                  <CloudDownloadIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Container>
  );
};

export default DataMart; 