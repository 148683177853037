import React, { Fragment } from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';
import useStyles from './styles';
import logo from '../../images/logo512.png';

const Landing = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleEnter = () => {
    history.push('/login');
  };

  return (
    <Fragment>
      <Container maxWidth='md'>
        <div className={classes.logoContainer}>
          <img src={logo} width={64} height={64} alt={'Theta Lab'} />
          <Typography className={classes.logoTitle} variant='h1'>Theta Lab</Typography>
        </div>

        <div className={classes.section}>
          <Typography variant='h2'>Simplify Everything for Data Science</Typography>
          <Typography variant='h6'>
            Theta Lab provides JupyterLab as service to data engineer, data analyst and data scientist.
            </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant='h2'>Data Mart</Typography>
          <Typography variant='h6'>
            Theta Lab data mart is a data market place for user to access high quality datasets for projects.
          </Typography>
        </div>
        <div className={classes.pricingSection}>
          <Typography variant='h1'>Start from $10 / mo</Typography>
          <Typography variant='h6'>
            <strong>1 Shared CPU</strong> - <strong>614 MB RAM</strong> - <strong>20 GB Storage</strong>
          </Typography>
        </div>
        <div className={classes.buttonSection}>
          <Button onClick={handleEnter} color='primary' variant='outlined' size='large'>Get Start Now</Button>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Landing;