import firebase from 'firebase/app';
import 'firebase/firestore';

export const useAccount = () => {
  const getAccount = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      try {
        const accountResult = await firebase.firestore().collection('Accounts').where('uid', '==', user.uid).get();
        if (!accountResult.empty && accountResult.docs[0]) {
          return accountResult.docs[0].data();
        }
      } catch (err) {
        console.error(err);
      }
      return null;
    }
  };


  return {
    getAccount,
  };
};