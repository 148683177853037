import defaultTheme from './default';

import { createMuiTheme } from '@material-ui/core';

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      padding: '10px 0',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      padding: '10px 0',
    },
    h3: {
      fontSize: '1.64rem',
      fontWeight: 700,
      padding: '10px 0',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.285rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.142rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none'
    }
  }
} as any;

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};