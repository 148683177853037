import React from 'react';
import { LinearProgress } from '@material-ui/core';
import useStyles from './styles';

const PageLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.progressContainer}>
        <LinearProgress variant='query' color='secondary' />
        <br />
        <LinearProgress variant='query' />
        <br />
        <LinearProgress variant='query' color='secondary' />
      </div>
    </div>
  );
};

export default PageLoading;