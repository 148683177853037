import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme: any) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      position: 'relative',
      flex: 1,
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
      marginBottom: 40,
      marginTop: 30,
    },
    logoTitle: {
      padding: '5px  0',
    },
    section: {
      padding: 10,
    },
    pricingSection :{
      marginBottom: 40,
      marginTop: 30,
      textAlign: 'center'
    },
    buttonSection :{
      marginBottom: 40,
      marginTop: 30,
      textAlign: 'center'
    },
  }));