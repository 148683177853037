import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCZPXHJUnvosMs0vczn4pmhEXVUzDE_ZDA",
  authDomain: "thetalab.firebaseapp.com",
  databaseURL: "https://thetalab.firebaseio.com",
  projectId: "thetalab",
  storageBucket: "thetalab.appspot.com",
  messagingSenderId: "806060721421",
  appId: "1:806060721421:web:24678f4e6e4321ef9adc44"
};

firebase.initializeApp(firebaseConfig);
